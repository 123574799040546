// extracted by mini-css-extract-plugin
export var background_azure = "heroanimation-module--background_azure--aPeUT";
export var background_chamois = "heroanimation-module--background_chamois--Oubys";
export var background_delta = "heroanimation-module--background_delta--Rzm1s";
export var background_dusky_rose = "heroanimation-module--background_dusky_rose--KxIKU";
export var background_grain_brown = "heroanimation-module--background_grain_brown--SKD41";
export var background_grenadier = "heroanimation-module--background_grenadier--Y5xBf";
export var background_grey_chateau = "heroanimation-module--background_grey_chateau--KtK92";
export var background_primary_blue = "heroanimation-module--background_primary_blue--NBU8J";
export var background_regent_st_blue = "heroanimation-module--background_regent_st_blue--YOp1B";
export var background_white = "heroanimation-module--background_white--9cNDC";
export var bg = "heroanimation-module--bg--kOAAd";
export var bg_mobile = "heroanimation-module--bg_mobile--wUZky";
export var position_left = "heroanimation-module--position_left--kAZuz";
export var position_right = "heroanimation-module--position_right--FwSij";
export var product = "heroanimation-module--product--niTKE";
export var product_mobile = "heroanimation-module--product_mobile--YS38O";
export var wrapper = "heroanimation-module--wrapper--oPWdV";