import * as React from "react"
import { Scroll } from "scrollex"
import * as styles from "./heroanimation.module.scss"
import classNames from "classnames"
import DEFAULT_SPRING_CONFIGS from "../../../../config/springs"

const HeroAnimation = ({ image, position, background_color }) => {
  const keyframes = {
    product: ({ section }) => ({
      [section.topAt("container-top")]: {
        skewX: "0deg",
        skewY: "0deg",
      },
      [section.height * 0.5]: {
        skewX: "5deg",
        skewY: "-5deg",
      },
    }),
    bg: ({ section }) => ({
      [section.topAt("container-top")]: {
        skewX: "20deg",
      },
      [section.height * 0.5]: {
        skewX: "-20deg",
      },
    }),
    bg_mobile: ({ section }) => ({
      [section.topAt("container-top")]: {
        skewY: "20deg",
      },
      [section.height]: {
        skewY: "-20deg",
      },
    }),
  }

  const heroClasses = classNames(
    styles.wrapper,
    {
      [styles.position_left]: position === "left",
      [styles.position_right]: position === "right",
    },
    styles[`background_${background_color?.toLowerCase().replaceAll(" ", "_")}`]
  )

  return (
    <Scroll.Section className={heroClasses}>
      {/* Desktop */}

      <Scroll.Item
        className={styles.bg}
        keyframes={keyframes.bg}
        springs={DEFAULT_SPRING_CONFIGS}
      />

      <Scroll.Item
        className={styles.product}
        keyframes={keyframes.product}
        springs={DEFAULT_SPRING_CONFIGS}
      >
        {image}
      </Scroll.Item>

      {/* Mobile */}

      <Scroll.Item
        className={styles.bg_mobile}
        keyframes={keyframes.bg_mobile}
        springs={DEFAULT_SPRING_CONFIGS}
      />

      <Scroll.Item
        className={styles.product_mobile}
        keyframes={keyframes.product}
        springs={DEFAULT_SPRING_CONFIGS}
      >
        {image}
      </Scroll.Item>
    </Scroll.Section>
  )
}

export default HeroAnimation
