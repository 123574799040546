import { graphql } from "gatsby"
import * as React from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import Spacer from "../components/01_atoms/Spacer/Spacer"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import HeroAnimation from "../components/03_organisms/animations/HeroAnimation/HeroAnimation"
import { RenderComponent } from "../components/components"
import Layout from "../components/layout"
import Seo from "../components/seo"

const TexturesPage = ({ data }) => {
  const pageData = data.allNodeBasicPage.nodes[0]

  const components = pageData?.relationships?.field_components

  return (
    <Layout>
      <Seo
        title={pageData.field_meta_title}
        description={pageData.field_meta_description}
      />
      {/* <Helmet
        bodyAttributes={{
          class: "noblefoods-page",
        }}
      /> */}

      <ImageTextBlock
        height="100"
        className="hero"
        image={pageData.relationships?.field_image}
        image_position="left"
        background_color="Azure"
        animation={
          <HeroAnimation
            image={<img src="/img/heroes/hero_products.png" alt="Contact" />}
            position="left"
            background_color="Regent St Blue"
          />
        }
      >
        <Heading size="xxl" spacing="l">
          {pageData.title}
        </Heading>
        <Paragraph>
          <span dangerouslySetInnerHTML={{ __html: pageData.body.processed }} />

          {/* <Button to="/" type="link">
            Go to EMPWR
          </Button>
          <a className="link" href="#block_190" data-link>
            Learn more
          </a> */}
        </Paragraph>
      </ImageTextBlock>

      {components?.map((component, index) => RenderComponent(component, index))}
      <Spacer height={64} />
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export default TexturesPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNodeBasicPage(
      filter: {
        status: { eq: true }
        langcode: { eq: $language }
        path: { alias: { eq: "/which-texture-do-you-prefer" } }
      }
    ) {
      nodes {
        title
        body {
          processed
        }
        field_image {
          drupal_internal__target_id
        }
        path {
          alias
        }
        field_meta_description
        field_meta_title
        relationships {
          field_image {
            ...imageFragment
          }
          field_components {
            ... on Node {
              ...imageTextFragment
              ...productList
              ...standout
              ...videoFragment
              ...paragraph__card_collectionFragment
            }
          }
        }
        drupal_internal__nid
      }
    }
  }
`
