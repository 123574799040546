const DEFAULT_SPRING_CONFIGS = {
  scale: {
    mass: 0.05,
    damping: 20,
    stiffness: 200,
  },

  skewX: {
    mass: 0.05,
    damping: 20,
    stiffness: 200,
  },
  skewY: {
    mass: 0.05,
    damping: 20,
    stiffness: 200,
  },
  translateX: {
    mass: 0.05,
    damping: 20,
    stiffness: 200,
  },
  translateY: {
    mass: 0.05,
    damping: 20,
    stiffness: 200,
  },

  backgroundColor: {
    mass: 0.05,
    damping: 20,
    stiffness: 200,
  },
}

export default DEFAULT_SPRING_CONFIGS
